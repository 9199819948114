<template>
    <div id="account-manage">
        账号管理
    </div>
</template>

<script>
export default {
    name: 'AccountManage', // 账号管理
}
</script>

<style lang="less" scoped>
</style>